<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Warehouses">
                
                <b-button v-if="$hasPermission($permissions.AddWarehouses)" variant="primary" @click="$router.push('/warehouse/add')">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="warehouses" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.EditWarehouses)" variant="warning" @click="$router.push(`/warehouse/${props.row.id}/edit`)" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveWarehouses)" variant="danger" @click="removeWarehouse(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'

    export default {
        components: {
            BCard,
            BOverlay,
            BasicTable,
            BButton
        },
        data() {
            return {
                dataLoaded: false,
                warehouses: [],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/warehouse')
                loadPromise.then(function(response) {
                    thisIns.warehouses = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            async removeWarehouse(id) {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this warehouse?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/warehouse/${  id}`)
                    this.$printSuccess('Sale removed')
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)                    
                } finally {
                    this.loadData()
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>